import globalApi from '@/request/globalApi/globalApi'
import { fireDeleteAlert } from '@/request/globalApi/interceptors/globalApiRequestDeleteInterceptor'

const END_POINT = 'api/product'

// --------------------
//    API Requests
// --------------------

// PRODUCTS LIST

export const fetchProductsRequest = queryParams => globalApi.get(END_POINT, { params: queryParams })

// MAIN INFORMATIONS

export const postProductRequest = (product, message) => globalApi.post(END_POINT, product, { message })

export const patchProductRequest = (productId, product, message) => globalApi.patch(`${END_POINT}/${productId}`, product, { message })

export const deleteProductRequest = productId => fireDeleteAlert().then(isConfirmed => isConfirmed && globalApi.delete(`${END_POINT}/${productId}/delete`))

export const fetchProductRequest = productId => globalApi.get(`${END_POINT}/${productId}`)

// ADDITIONAL INFORMATIONS

export const fetchProductPlainRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/additional-informations/${variantId}`)

export const patchProductPlainRequest = ({ productId, variantId }, product, message) => globalApi.patch(`${END_POINT}/${productId}/additional-informations/${variantId}`, product, { message })

// LOCATIONS

export const fetchProductLocationsRequest = productId => globalApi.get(`${END_POINT}/${productId}/locations`)

export const patchProductLocationsRequest = (productId, product) => globalApi.patch(`${END_POINT}/${productId}/locations`, product)

// PRICE MANAGEMENT

export const fetchProductPriceRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/price-management/${variantId}`)

export const patchProductPriceRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/price-management/${variantId}`, product)

// CALENDAR

export const fetchProductPriceCalendarRequest = (queryParams, productId, variantId) => globalApi.get(`${END_POINT}/${productId}/price-management/${variantId}/calendar`, { params: queryParams })

export const fetchProductPriceCalendarByIdRequest = (productId, variantId, rangeId) => globalApi.get(`${END_POINT}/${productId}/price-management/${variantId}/calendar/${rangeId}`)

export const postProductPriceCalendarRequest = (productId, variantId, product) => globalApi.post(`${END_POINT}/${productId}/price-management/${variantId}/calendar`, product)

export const patchProductPriceCalendarRequest = (productId, variantId, rangeId, product) => globalApi.patch(`${END_POINT}/${productId}/price-management/${variantId}/calendar/${rangeId}`, product)

export const deleteProductPriceCalendarRequest = (productId, variantId, rangeId) => globalApi.delete(`${END_POINT}/${productId}/price-management/${variantId}/calendar/${rangeId}`)

// NEGOTIATED RATES

export const fetchProductNegotiatedRatesRequest = (productId, variantId, queryParams) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`, { params: queryParams })

export const postProductNegotiatedRateRequest = ({ productId, variantId }, product) => globalApi.post(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`, product)

export const patchProductNegotiatedRateRequest = ({ productId, variantId, negotiatedRateId }, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate/${negotiatedRateId}`, product)

export const fetchProductNegotiatedRateByIdRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate`)

export const deleteProductNegotiatedRateByIdRequest = (productId, variantId, negotiatedRateId) => fireDeleteAlert().then(isConfirmed => isConfirmed
  && globalApi.delete(`${END_POINT}/${productId}/variant/${variantId}/negotiated-rate/${negotiatedRateId}`))

// CANCELLATION CONDITIONS

// ? Never used
// export const fetchProductCancellationConditionsRequest = (productId, variantId) => globalApi.get(`${END_POINT}/${productId}/variant/${variantId}/cancellation-conditions`)

// ? Never used
// export const patchProductCancellationConditionsRequest = (productId, variantId, product) => globalApi.patch(`${END_POINT}/${productId}/variant/${variantId}/cancellation-conditions`, product)

// RELATED

export const fetchProductRelatedRequest = productId => globalApi.get(`${END_POINT}/${productId}/related`)
