<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">
        {{ $t('trip.shop.alert.fetching_data_product') }}
      </h4>
      <div class="alert-body">
        {{ $t('trip.shop.alert.no_items') }}
      </div>
    </b-alert>
    <!--      Content-->
    <b-overlay v-if="productLoaded && product" spinner-medium>
      <b-card v-if="productLoaded && product" no-body>
        <b-card-body>
          <b-row class="my-2">
            <!-- Left: Product Image Container -->
            <b-col cols="4" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
              <div v-if="!product.mainVariant" class="d-flex align-items-center justify-content-center">
                <div class="product-img">
                  <b-img
                    :src="require('@/assets/images/logo/logo.png')"
                    fluid
                  />
                </div>
              </div>
              <div v-if="product.mainVariant && product.mainVariant.files.length < 2" class="d-flex align-items-center justify-content-center">
                <div class="product-img">
                  <b-img
                    :src="product.mainVariant.files.length ? product.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')"
                    fluid
                  />
                </div>
              </div>
              <div v-else-if="product.mainVariant && product.mainVariant.files.length > 1" class="d-flex align-items-center justify-content-center w-100">
                <swiper class="swiper-responsive-breakpoints" :options="swiperOptions">
                  <swiper-slide v-for="file in product.mainVariant.files" :key="file.id">
                    <div class="img-container">
                      <b-img
                        :src="file.fileUrls[300]"
                        fluid
                      />
                    </div>
                  </swiper-slide>
                  <!-- Add Arrows -->
                  <div slot="button-next" class="swiper-button-next" />
                  <div slot="button-prev" class="swiper-button-prev" />
                </swiper>
              </div>
            </b-col>
            <!-- Right: Product Details -->
            <b-col cols="7">
              <!-- Product Name -->
              <div>
                <h4 v-if="product.name" class="mb-0 mr-1">
                  <b-link class="font-weight-bold">
                    {{ product.name | trans }}
                  </b-link>
                </h4>
                <b-card-text v-if="product.mainCategory.name" class="mb-0">
                  <span>{{ product.mainCategory.name | trans }}</span>
                </b-card-text>
                <!-- Product Brand -->
                <b-card-text class="item-company my-0">
                  <span>{{ $t('common.by') }}</span>
                  <b-link
                    class="company-name"
                    :to="{
                      name: 'provider-details',
                      params: {
                        provider_id: product.organization.id,
                        trip_id: $route.params.trip_id,
                        step_id: $route.params.step_id,
                        event_id: $route.params.event_id,
                        airport_id: $route.params.airport_id,
                      },
                    }"
                  >
                    {{ product.organization.legalName ? product.organization.legalName : product.organization.commercialName }}
                  </b-link>
                </b-card-text>
                <div v-if="product.mainVariant && product.mainVariant.packing" class="mt-1">
                  <p class="pb-0 mb-0"> {{ $t('service.prices.type') }} : {{ product.mainVariant.pricingType | enumTranslate('pricing_type') }}</p>
                  <p class="pb-0 mb-0"> {{ $t('service.prices.type_measurement') }} : {{ product.mainVariant.packing.name | trans }}</p>
                </div>
                <div v-if="product.minPrice">
                  <p class="pb-0 mb-0">{{ $t('pricing.unit_price_before_tax') }} : {{ product.minPrice.displayPriceExcludingTaxes | priceFormat }}</p>
                  <p class="pb-0 mb-0">{{ $t('pricing.unit_price_including_tax') }} : {{ product.minPrice.displayPriceIncludingTaxes | priceFormat }}</p>
                </div>
              </div>

              <!-- Price And Ratings -->
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 v-if="!product.minPrice" class="item-price mr-1 mb-1">
                  {{ $t('trip.shop.price_on_quote') }}
                </h4>
              </div>
              <!-- TODO: useless and delete it? -->
              <!-- <b-card-text v-if="false">
                <div class="col-12 px-0 py-50">
                  <font-awesome-icon icon="users" style="width: 25px" />
                  <span class="mx-50">
                    {{ product.passengers }}
                    {{ $tc('trip.event.passenger_number', product.passengers) }}
                  </span>
                </div>
                <div class="col-12 px-0 py-50">
                  <font-awesome-icon icon="suitcase" style="width: 25px" />
                  <span class="mx-50">
                    {{ product.luggages }}
                    {{ $tc('trip.event.luggage_number', product.luggages, product.luggages) }}
                  </span>
                </div>
                <div class="col-12 px-0 py-50">
                  <font-awesome-icon icon="tachometer-alt" style="width: 25px" />
                  <span class="mx-50">{{ product.speed }} km/h </span>
                </div>
              </b-card-text> -->
              <!-- Product Description -->
              <!-- <b-card-text>
                {{ product.mainVariant.description | trans }}
              </b-card-text> -->
              <!-- TODO VHTML check if ok -->
              <b-card-title v-if="product.mainVariant && product.mainVariant.description" class="mt-1">
                {{ $tc('common.description') }}:
              </b-card-title>
              <b-card-text v-if="product.mainVariant && product.mainVariant.description">
                <div v-html="product.mainVariant.description[userLang]" />
              </b-card-text>
              <hr />
              <b-card-title v-if="product.mainVariant && product.mainVariant.variantAttributes.length">
                {{ $tc('service.additional_informations.characteristic', product.mainVariant.variantAttributes.length) }}
                :
              </b-card-title>
              <ul v-if="product.mainVariant && product.mainVariant.variantAttributes.length">
                <li v-for="attribute in product.mainVariant.variantAttributes" :key="attribute.id">
                  {{ attribute.attribute.name | trans }} :
                  <span v-if="attribute.inputValues">{{ attribute.inputValues | trans }}</span>
                  <span v-if="attribute.selectValue">{{ attribute.selectValue.name | trans }}</span>
                  <ul v-if="attribute.choiceValues">
                    <li v-for="choice in attribute.choiceValues" :key="choice.id" class="my-1">
                      <b-link :href="choice.iconFileUrl" target="_blank">
                        <img v-if="choice.iconFileUrl" height="40px" :src="choice.iconFileUrl" :alt="choice.name">
                      </b-link>
                      {{ choice.name | trans }}
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="d-flex align-items-end justify-content-end flex-column">
                <div class="d-flex flex-column flex-sm-row pt-1">
                  <b-button
                    v-if="selected"
                    v-ripple.400
                    class="btn-cart"
                    :to="{
                      name: 'shop-checkout',
                      params: {
                        trip_id: $route.params.trip_id,
                        step_id: $route.params.step_id,
                        event_id: $route.params.event_id,
                        airport_id: $route.params.airport_id,
                      },
                    }"
                  >
                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                    <span>
                      <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
                    </span>
                  </b-button>
                  <b-button v-else v-ripple.400 class="btn-cart" @click="patchCart(product)">
                    <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                    <span>
                      <span v-if="product.typology === 'service'">{{ $t('trip.shop.add_to_cart') }}</span>
                      <span v-else>{{ product.selected ? $t('trip.shop.book_flight') : $t('trip.shop.helicopter.action.select') }}</span>
                    </span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
        <hr />

        <!-- TODO: Delete it ? -->
        <transfer-availability v-if="false" />
        <shop-product-details-related-product :related-products="relatedProducts" />
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { fetchOrganizationProductRequest } from '@/request/globalApi/requests/apiRequests'
import Cart from '@/models/Cart'
import transferAvailability from './transfer/transferAvailability.vue'
import ShopProductDetailsRelatedProduct from './ShopDetailsRelatedProduct.vue'

export default {
  name: 'ShopDetails',
  components: {
    transferAvailability,
    ShopProductDetailsRelatedProduct,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      productLoaded: false,
      product: null,
      productQuantity: 1,
      relatedProducts: null,
      cart: new Cart(),
      selected: false,
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  watch: {
    $route() {
      this.APIFetchProduct()
    },
  },
  mounted() {
    this.APIFetchProduct()
  },
  methods: {
    APIFetchProduct() {
      this.productLoaded = false
      const productId = this.$route.params.product_id
      const organizationId = this.$route.params.provider_id
      const tripStepId = this.$route.params.event_id
      fetchOrganizationProductRequest(tripStepId, organizationId, productId)
        .then(r => {
          const { product, selected } = r.data
          this.product = product
          this.selected = selected
        })
        .catch(() => {
          this.product = undefined
        })
        .finally(() => {
          this.productLoaded = true
        })
    },
    patchCart(product) {
      const tripStepId = this.$route.params.event_id
      this.cart.patch(tripStepId, product.mainVariant.id, 1).then(() => {
        this.selected = true
      })
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    return {
      swiperOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '../../../../node_modules/swiper/css/swiper.css';
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
.badge-price {
  position: absolute;
  right: 8px;
  top: -24px;
}
</style>
