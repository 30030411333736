var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.relatedProducts.length)?_c('b-card-body',[_c('div',{staticClass:"mt-4 mb-2 text-center"},[_c('h4',[_vm._v(_vm._s(_vm.$t('trip.shop.related_services')))])]),_c('b-overlay',{attrs:{"show":_vm.productsRelatedLoaded,"spinner-medium":""}},[_c('swiper',{staticClass:"swiper-responsive-breakpoints px-4 py-2",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.relatedProducts),function(product,index){return _c('swiper-slide',{key:index},[_c('b-link',{attrs:{"to":{
            name: 'shop-service-details',
            params: {
              product_id: product.id,
              provider_id: _vm.$route.params.provider_id,
              trip_id: _vm.$route.params.trip_id,
              step_id: _vm.$route.params.step_id,
              event_id: _vm.$route.params.event_id,
              airport_id: _vm.$route.params.airport_id,
            },
          }}},[_c('div',{staticClass:"item-heading"},[_c('h5',{staticClass:"text-truncate mb-0"},[_vm._v(" "+_vm._s(_vm._f("trans")(product.name))+" ")]),(product.organization)?_c('small',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.$t('common.by'))+" "+_vm._s(product.organization.name))]):_vm._e()]),_c('div',{staticClass:"img-container w-50 mx-auto py-75"},[_c('b-img',{attrs:{"src":product.mainVariant && product.mainVariant.files && product.mainVariant.files.length
                  ? product.mainVariant.files[0].fileUrls[300]
                  : require('@/assets/images/logo/logo.png'),"fluid":""}})],1),_c('div',{staticClass:"item-meta"},[_c('p',{staticClass:"card-text text-primary mb-0"},[(product.minPrice)?_c('span',[_vm._v(" "+_vm._s(_vm._f("priceFormat")(product.minPrice.displayPriceIncludingTaxes))+" "+_vm._s(_vm.$t('trip.shop.cart.incl_taxes')))]):_c('span',[_vm._v(_vm._s(_vm.$t('trip.shop.price_on_quote')))])])])])],1)}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"})],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }