<template>
  <b-card-body v-if="relatedProducts.length">
    <div class="mt-4 mb-2 text-center">
      <h4>{{ $t('trip.shop.related_services') }}</h4>
    </div>

    <!-- Swiper -->
    <b-overlay :show="productsRelatedLoaded" spinner-medium>
      <swiper class="swiper-responsive-breakpoints px-4 py-2" :options="swiperOptions">
        <swiper-slide v-for="(product, index) in relatedProducts" :key="index">
          <b-link
            :to="{
              name: 'shop-service-details',
              params: {
                product_id: product.id,
                provider_id: $route.params.provider_id,
                trip_id: $route.params.trip_id,
                step_id: $route.params.step_id,
                event_id: $route.params.event_id,
                airport_id: $route.params.airport_id,
              },
            }"
          >
            <div class="item-heading">
              <h5 class="text-truncate mb-0">
                {{ product.name | trans }}
              </h5>
              <small v-if="product.organization" class="text-body"> {{ $t('common.by') }} {{ product.organization.name }}</small>
            </div>
            <div class="img-container w-50 mx-auto py-75">
              <b-img
                :src="
                  product.mainVariant && product.mainVariant.files && product.mainVariant.files.length
                    ? product.mainVariant.files[0].fileUrls[300]
                    : require('@/assets/images/logo/logo.png')
                "
                fluid
              />
            </div>
            <div class="item-meta">
              <p class="card-text text-primary mb-0">
                <span v-if="product.minPrice"> {{ product.minPrice.displayPriceIncludingTaxes | priceFormat }} {{ $t('trip.shop.cart.incl_taxes') }}</span>
                <span v-else>{{ $t('trip.shop.price_on_quote') }}</span>
              </p>
            </div>
          </b-link>
        </swiper-slide>

        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </b-overlay>
  </b-card-body>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { fetchProductRelatedRequest } from '@/request/globalApi/requests/productRequests'

export default {
  name: 'ShopDetailsRelatedProduct',

  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      productsRelatedLoaded: false,
      relatedProducts: [],
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.language
    },
  },
  watch: {
    $route() {
      this.APIFetchProductRelated()
    },
  },
  mounted() {
    this.APIFetchProductRelated()
  },
  methods: {
    APIFetchProductRelated() {
      this.productsRelatedLoaded = true
      const productId = this.$route.params.product_id
      fetchProductRelatedRequest(productId)
        .then(r => {
          this.relatedProducts = r.data.relatedProducts
        })
        .finally(() => {
          this.productsRelatedLoaded = false
        })
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 5,
      spaceBetween: 55,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1600: {
          slidesPerView: 4,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    }

    return {
      swiperOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '../../../../node_modules/swiper/css/swiper.css';
</style>
